/*
<template>
  <div>
    <div class="p-6 mb-5 bg-white rounded-xl shadow-lg">
      <div class="flex">
        <h2 class="text-left text-md text-gray-600">
          <b>Filtros de búsqueda</b>
        </h2>
      </div>
      <div class="w-full flex flex-col gap-4">
        <div
          class="w-full grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-4"
        >
          <BaseInput
            type="text"
            label="Código de queja radicada"
            placeholder="Ej: 1144578546"
            v-model="form.codigo_queja"
          />
          <BaseSelect
            label="Tipo de identificación"
            :selectOptions="formOptions.optionsIdentificationType"
            v-model="form.tipo_id_CF"
          />
          <BaseInput
            type="text"
            label="Número de identificación"
            placeholder="Ej: 1144578546"
            v-model="form.numero_id_CF"
          />
          <BaseSelect
            label="Estado de la queja"
            class="self-end"
            :selectOptions="formOptions.optionsStates"
            v-model="form.estado_cod"
          />
        </div>
        <div
          class="w-full max-w-2xl flex flex-col items-center md:flex-row content-end gap-2"
        >
          <Button
            styles="secondary"
            class="h-10 w-44 mt-auto"
            @click.native="onSearch"
            :disable="disabled"
          >
            <a-icon type="search" />
            Buscar
          </Button>
          <Button
            v-if="isFilter"
            :danger="true"
            :invert="true"
            class="h-10 w-44 mt-auto"
            @click.native="deleteFilters"
          >
            Limpiar filtros
          </Button>
          <Button
            styles="secondary"
            :invert="true"
            class="h-10 w-48 flex-shrink-0 min-w-content mt-auto"
            @click.native="downloadComplaints"
            :loading="loadingExcel"
            :disable="loadingExcel"
          >
            <a-icon v-if="!loadingExcel" type="download" />
            Descargar quejas
          </Button>
          <Button
            styles="secondary"
            :invert="true"
            class="h-10 w-48 mt-auto"
            @click.native="sync"
            :loading="syncing"
            :disable="syncing"
          >
            <a-icon v-if="!syncing" type="sync" />
            Sincronizar
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

const initialForm = {
  numero_id_CF: '',
  tipo_id_CF: '',
  codigo_queja: '',
  estado_cod: '',
}

export default {
  props: ['query'],
  data() {
    return {
      form: { ...initialForm },
      disabled: false,
      showModal: false,
      loadingExcel: false,
      syncing: false,
    }
  },
  computed: {
    ...mapState({ formOptions: 'form' }),
    isFilter() {
      let clean = false
      Object.values(this.form).forEach((value) => {
        if (value !== '') {
          clean = true
          return
        }
      })
      return clean
    },
  },

  methods: {
    async onSearch() {
      let form = this.cleanForm()
      let query = { ...this.query, ...form }
      await this.$router
        .push({
          query: { ...query },
        })
        .catch(() => {})
    },
    cleanForm() {
      let newForm = {}
      for (let i in this.form) {
        if (this.form[i] !== '') {
          newForm[i] = this.form[i]
        }
      }
      return newForm
    },
    openModal() {
      this.showModal = true
    },
    closeModal() {
      this.showModal = false
    },
    async deleteFilters() {
      Object.keys(this.form).forEach((key) => {
        this.form[key] = ''
      })
      this.deleteQuery()
    },
    async deleteQuery() {
      this.$emit('clean-query')
    },
    async downloadComplaints() {
      this.loadingExcel = true
      let response = await this.$api.complaints.downloadComplaintsExcel()
      this.loadingExcel = false
      if (response.status !== 200) {
        this.$showNotification('error', response.data)
        return
      }
      let link = document.createElement('a')
      link.setAttribute('href', response.data.download_url)
      link.setAttribute('download', 'lista_quejas.xls')
      link.click()
    },
    async sync() {
      this.syncing = true
      const response = await this.$api.axios.get('/api/sfc')
      this.syncing = false
      if (response.status !== 200) {
        this.$showNotification('error', 'Hubo un error al sincronizar')
        return
      }
      this.$showNotification('success', 'Sincronización completa')
      this.$emit('fetch-complaints')
    },
  },
}
</script>

<style></style>
*/
