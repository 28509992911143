<template>
  <div class="w-full">
    <ComplaintsFilter
      :query="query"
      @set-query="(newQuery) => (query = { ...newQuery })"
      @clean-query="cleanQuery()"
      @fetch-complaints="fetchComplaints"
    />
    <div class="py-12" v-if="legend">
      <b class="text-center text-4xl mx-auto text-gray-400">{{ legend }}</b>
    </div>
    <div
      v-if="complaints"
      class="bg-white overflow-x-auto rounded-xl border border-gray-300"
    >
      <a-table
        :columns="columns"
        :data-source="complaints"
        :pagination="pagination"
        :loading="loading"
        @change="onTableChange"
      >
        <template slot="fecha_creacion" slot-scope="text, record">
          {{
            record.fecha_creacion ? record.fecha_creacion.split('T')[0] : '-'
          }}
        </template>
        <template slot="is_replicated" slot-scope="text, record">
          <p v-if="record.is_replicated" class="flex items-center gap-2">
            <a-icon type="warning" class="text-yellow-500" />
            Réplica
          </p>

          <p
            v-if="record.desistimiento_queja === '1'"
            class="flex items-center gap-2"
          >
            <a-icon type="info-circle" class="text-green-500" />
            Desistida
          </p>
        </template>
        <template slot="history" slot-scope="text, record">
          <div class="flex justify-center gap-2">
            <span class="text-secondary text-2xl cursor-pointer"
              ><a-icon type="eye" @click.native="complaintHistory(record)"
            /></span>
          </div>
        </template>
        <template slot="actions" slot-scope="text, record">
          <div class="flex gap-2">
            <Button
              v-if="closeComplaint(record)"
              class="w-40"
              :danger="true"
              @click.native="goToChangeComplaintStatus(record)"
            >
              <a-icon type="swap" /> Cambiar estado
            </Button>
            <Button v-else class="w-40" @click.native="manageComplaint(record)">
              <a-icon type="setting" /> Gestionar
            </Button>
          </div>
        </template>

        <!-- FILTERS -->
      </a-table>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import ComplaintsFilter from './ComplaintsFilter'

const initialQuery = {
  page: 1,
  page_limit: 10,
  order_by: undefined,
  direction: undefined,
}

const initialPagination = {
  total: 1,
  current: 1,
  pageSize: 10,
  hideOnSinglePage: true,
}

export default {
  components: {
    ComplaintsFilter,
  },
  data() {
    return {
      legend: '',
      complaints: [],
      selectedRowKeys: [], // Check here to configure the default column
      loading: false,
      showModal: false,
      pagination: { ...initialPagination },
      query: { ...initialQuery },
    }
  },
  computed: {
    ...mapState({
      optionsStatus: (state) => {
        let options = state.form.optionsStatus.map((option) => {
          return { text: option.name, value: option.id }
        })
        return options
      },
    }),
    columns() {
      const columns = [
        {
          title: 'No. de Radicado',
          dataIndex: 'codigo_queja',
          key: 'codigo_queja',
          sorter: () => {},
        },
        {
          title: 'Nombre responsable',
          dataIndex: 'nombres',
          key: 'nombres',
          sorter: () => {},
        },
        {
          title: 'Motivo',
          dataIndex: 'motivo_valor',
          key: 'motivo_valor',
        },
        {
          title: 'Fecha creación',
          dataIndex: 'fecha_creacion',
          key: 'fecha_creacion',
          scopedSlots: { customRender: 'fecha_creacion' },
          sorter: () => {},
        },
        {
          title: 'Estado',
          dataIndex: 'estado_valor',
          key: 'estado_valor',
        },
        {
          title: 'Notas',
          dataIndex: 'is_replicated',
          key: 'is_replicated',
          scopedSlots: { customRender: 'is_replicated' },
        },
        {
          title: 'Histórico',
          dataIndex: 'history',
          key: 'history',
          scopedSlots: { customRender: 'history' },
        },
        {
          title: 'Acciones',
          dataIndex: 'actions',
          key: 'actions',
          scopedSlots: { customRender: 'actions' },
        },
      ]
      return columns
    },
  },
  async created() {
    await this.setQuery()
    this.fetchComplaints()
  },
  watch: {
    '$route.query': function (newValue, oldValue) {
      if (newValue != oldValue) {
        this.fetchComplaints()
      }
    },
  },
  methods: {
    manageComplaint(record) {
      this.$router.push(`/dashboard/complaints/${record.key}/`).catch(() => {})
    },
    goToChangeComplaintStatus(record) {
      this.$router
        .push(`/dashboard/complaints/${record.key}/response`)
        .catch(() => {})
    },
    complaintHistory(record) {
      this.$router
        .push(`/dashboard/complaints/${record.key}/history`)
        .catch(() => {})
    },
    async fetchComplaints() {
      this.loading = true
      let query = this.$route.query
      let response = await this.$api.complaints.getComplaints(query)
      this.query = this.$route.query
      this.loading = false
      if (response.status !== 200) {
        this.$showNotification('error', 'Hubo un error al recuperar las quejas')
        return
      }
      this.pagination.total = response.data.num_total_elements
      this.pagination.current = response.data.current_page
      this.complaints = response.data.result
    },
    onTableChange(...params) {
      this.query = { ...this.$route.query }

      const pagination = params[0]
      const filters = params[1]
      const sort = params[2]
      // Sort
      if (sort.order) {
        this.query.order_by = sort.field
        this.query.direction = sort.order === 'ascend' ? 'asc' : 'desc'
      } else {
        this.query.order_by = undefined
        this.query.direction = undefined
      }
      // Filters
      if (filters) {
        for (let filter in filters) {
          this.query[filter] = filters[filter]
        }
      }
      // Pagination
      this.pagination.current = pagination.current
      this.query.page = pagination.current
      this.query.page_limit = pagination.pageSize
      this.setQuery(true)
    },
    async setQuery(updateQuery) {
      if (!this.$route.query.page || updateQuery) {
        await this.$router.push({
          query: { ...this.query },
        })
      } else {
        this.pagination.page = parseInt(this.$route.query.page)
        this.pagination.page_limit =
          parseInt(this.$route.query.page_limit) || 10
        await this.setQuery(true)
      }
    },
    async cleanQuery() {
      this.query = { ...initialQuery }
      this.pagination = { ...initialPagination }
      await this.$router.push({
        query: { ...this.query },
      })
    },
    closeComplaint(record) {
      if (record.desistimiento_queja === '1' && record.estado_cod !== '4') {
        return true
      }
      if (record.is_replicated && record.estado_cod === '4') {
        return true
      }
      return false
    },
  },
}
</script>
