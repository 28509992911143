<template>
  <div class="h-min-content w-full">
    <ManageComplaints />
  </div>
</template>

<script>
import ManageComplaints from '@/components/Dashboard/Complaints/ManageComplaints.vue'
export default {
  components: {
    ManageComplaints,
  },
}
</script>

<style></style>
